import { useVault } from '~/hooks/useItem'
import { NftCard } from '~/components/NftCard'
import { NftCardLoading } from '~/components/CollectionsLoadingState'
import { VaultWithCollectionAndTokenId } from '~/domain/Vault'

interface VaultCardProps {
  vault: VaultWithCollectionAndTokenId
}

export const VaultCard: React.FC<VaultCardProps> = ({ vault }) => {
  const { data: item, isLoading, isError, error } = useVault(vault.collection, vault.tokenId, vault)

  if (isLoading) {
    return <NftCardLoading/>
  }

  if (isError) {
    console.log(error)

    return null
  }

  if (!item) return null

  return (
    <NftCard
      key={ vault.contract.address }
      collection={ vault.collection }
      nft={ item }
    />
  )
}
