import React from 'react'
import Link from 'next/link'
import { Organizer } from '~/domain/Organizer'
import { OrganizerCard } from './OrganizerCard'
import styles from './index.module.scss'
import linkStyles from '~/components/Link/index.module.scss'

interface FederationShowcaseProps {
  organizers: Organizer[]
}

export const FederationShowcase: React.FC<FederationShowcaseProps> = ({ organizers }) => {
  const carousel = organizers.map((organizer) => {
    return (
      <OrganizerCard
        key={ organizer.id }
        organizer={ organizer }
      />
    )
  })

  return (
    <div
      data-testid="federation-showcase"
      className={ styles['federation-showcase__slide'] }
    >
      <h1 className={ styles['federation-showcase__title'] }>
        Federations
      </h1>

      <p className={ styles['federation-showcase__description'] }>
        Trusted by sports governing bodies worldwide.
      </p>

      <div className={ styles['federation-showcase__carousel'] }>
        { carousel }
        { carousel }
      </div>

      <p className={ styles['federation-showcase__explain'] }>
        Do you represent a sports federation?
        <Link
          className={ `
            ${styles['federation-showcase__explain-link']}
            ${linkStyles['link']}
          ` }
          href="https://form.typeform.com/to/uRMmijY7"
          target="_blank"
          rel="noreferrer"
        >
          Get in touch with us!
        </Link>
      </p>
    </div>
  )
}
