import React from 'react'
import { MediaRenderer } from '@thirdweb-dev/react'
import { Series } from '~/domain/Series'
import styles from './index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export interface SeriesCardProps {
  series: Series
}

export const SeriesCard: React.FC<SeriesCardProps> = ({ series }) => {
  return (
    <a
      href={ `/series/${series.id}` }
      className={ styles['series-card__card'] }
    >
      <div className={ styles['series-card__header'] }>
        <span className={ styles['series-card__avatar'] }>
          <MediaRenderer
            src={ series.organizer.avatarUrlDark }
            alt={ `Card of ${series.name}` }
          />
        </span>
        <span className={ styles['series-card__organizer-acronym'] }>
          { series.organizer.acronym }
        </span>
        <span className={ styles['series-card__series-name'] }>
          { series.name }
        </span>
        <span className={ styles['series-card__organizer-name'] }>
          { series.organizer.name }
        </span>
        <FontAwesomeIcon
          className={ styles['series-card__icon'] }
          icon={ faArrowRight }
        />
      </div>
      <span className={ styles['series-card__image'] }>
        <MediaRenderer
          src={ series.cardImageUrl }
          alt={ `Card of ${series.name}` }
        />
      </span>
    </a>
  )
}
