import React from 'react'
import { VaultWithCollectionAndTokenId } from '~/domain/Vault'
import { VaultsDescription } from './VaultsDescription'
import { VaultCardsList } from './VaultCardsList'
import styles from './index.module.scss'

interface VaultsShowcaseProps {
  vaults: VaultWithCollectionAndTokenId[]
}

export const VaultsShowcase: React.FC<VaultsShowcaseProps> = ({ vaults }) => {
  return (
    <div
      className={ styles['vault-showcase__slide'] }
      data-testid="vault-showcase"
    >
      <div className={ styles['vault-showcase__container'] }>
        <VaultsDescription/>
        <VaultCardsList vaults={ vaults }/>
      </div>
    </div>
  )
}
