import React from 'react'
import { MediaRenderer } from '@thirdweb-dev/react'
import styles from './index.module.scss'

export const BannerVideo: React.FC = () => {
  return (
    <div className={ styles['banner-video'] }>
      <MediaRenderer
        src="https://cdn.leverade.com/market/chiliz/genesis-cinematic.mp4"
        poster="https://cdn.leverade.com/market/chiliz/genesis.jpg"
        alt="Legend & Chiliz NFT Cinematic Video"
        controls={ false }
        width="auto"
        className={ styles['banner-video__media'] }
      />
    </div>
  )
}
