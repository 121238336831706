import { NextPage } from 'next'
import { FeaturedNft } from '~/domain/FeaturedNft'
import { Athlete } from '~/domain/Athlete'
import { Organizer } from '~/domain/Organizer'
import { Series } from '~/domain/Series'
import { VaultWithCollectionAndTokenId } from '~/domain/Vault'
import { HtmlPageMetadata } from '~/components/HtmlPageMetadata'
import { siteName } from '~/constants/site'
import { HomeHeader } from './HomeHeader'
import { SeriesShowcase } from './SeriesShowcase'
import { AthleteShowcase } from './AthleteShowcase'
import { FederationShowcase } from './FederationShowcase'
import { HomeFooter } from './HomeFooter'
import { ChilizGenesisBanner } from './ChilizGenesisBanner'
import { ClubBanner } from './ClubBanner'
import { VaultsShowcase } from './VaultsShowcase'
import { Euro2024Banner } from './Euro2024Banner'
import { Paris2024Banner } from './Paris2024Banner'

export interface HomePageProps {
  featuredNft: FeaturedNft
  featuredSeries: Series[]
  featuredAthletes: Athlete[]
  featuredOrganizers: Organizer[]
  featuredVaults: VaultWithCollectionAndTokenId[]
  url: string
  ogImageUrl: string
}

export const HomePage: NextPage<HomePageProps> = ({
  featuredNft,
  featuredSeries,
  featuredAthletes,
  featuredOrganizers,
  featuredVaults,
  url,
  ogImageUrl,
}) => {
  const description =
    'The only marketplace where athletes, fans and collectors are connected through Official NFT Trophies'

  return (
    <>
      <HtmlPageMetadata
        title={ siteName }
        description={ description }
        url={ url }
        image={ ogImageUrl }
      />

      <HomeHeader nft={ featuredNft }/>

      <Euro2024Banner/>

      <Paris2024Banner/>

      <VaultsShowcase vaults={ featuredVaults }/>

      <AthleteShowcase athletes={ featuredAthletes }/>

      <ClubBanner/>

      <ChilizGenesisBanner/>

      <SeriesShowcase series={ featuredSeries }/>

      <FederationShowcase organizers={ featuredOrganizers }/>

      <HomeFooter />
    </>
  )
}
