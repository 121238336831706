import React from 'react'
import { Series } from '~/domain/Series'
import { SeriesCard } from './SeriesCard'
import styles from './index.module.scss'

export interface SeriesListProps {
    series: Series[]
}

export const SeriesList: React.FC<SeriesListProps> = ({ series }) => {
  const cards = series.map((item: Series) => {
    return (
      <SeriesCard
        series={ item }
        key={ item.id }
      />
    )
  })

  return (
    <div
      className={ styles['series-list__list'] }
      data-testid="series-showcase-list"
    >
      { cards }
    </div>
  )
}
