import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { LinkButton } from '~/components/LinkButton'
import styles from './index.module.scss'
import linkStyles from '~/components/HomePage/ClubBanner/BannerMain/Link/index.module.scss'

export const BannerMain: React.FC = () => {
  return (
    <div className={ styles['banner-main'] }>
      <div className={ styles['banner-main__title'] }>
        Join the Legend Euro2024 Staking Game!
      </div>

      <div className={ styles['banner-main__description'] }>
        Stake for your favorite teams and win awesome rewards. <br/>
        50,000 CHZ boost distributed across match pools!
      </div>

      <LinkButton
        href="/euro2024"
        size="medium"
        hierarchy="primary"
        icon={ faArrowRight }
        className={ linkStyles['link__button'] }
      >
        Start Now
      </LinkButton>
    </div>
  )
}
