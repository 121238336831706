import React from 'react'
import Link from 'next/link'
import { Athlete } from '~/domain/Athlete'
import styles from './index.module.scss'

interface AthleteCardProps {
  athlete: Athlete
}

export const AthleteCard: React.FC<AthleteCardProps> = ({ athlete }) => {
  const content = (
    <>
      <img
        className={ styles['athlete-card__image'] }
        src={ athlete.featuredImageUrl }
        alt={ `Card of ${athlete.name}` }
      />

      <div className={ styles['athlete-card__gradient'] }></div>

      <div className={ styles['athlete-card__data'] }>
        <div className={ styles['athlete-card__data__country'] }>
          <img
            src={ athlete.flagUrl }
            alt={ athlete.country }
            className={ styles['athlete-card__data__country__image'] }
          />

          <p className={ styles['athlete-card__data__country__text'] }>
            { athlete.country }
          </p>
        </div>

        <p className={ styles['athlete-card__data__name'] }>
          { athlete.name }
        </p>

        <p className={ styles['athlete-card__data__sport'] }>
          { athlete.sport }
        </p>
      </div>
    </>
  )

  return (
    <div className={ styles['athlete-card__card'] }>
      {
        athlete.museumUrl &&
        <Link
          href={ athlete.museumUrl ?? '' }
          target="_blank"
          rel="noreferrer"
        >
          { content }
        </Link>
      }

      {
        !athlete.museumUrl &&
          content
      }
    </div>
  )
}
