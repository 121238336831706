import React from 'react'
import { useFeature } from 'flagged'
import { BannerMain } from './BannerMain'
import { BannerVideo } from './BannerVideo'
import styles from './index.module.scss'

export const ChilizGenesisBanner: React.FC = () => {
  const isChilizGenesis = useFeature('chilizGenesis')

  if (!isChilizGenesis) return null

  return (
    <div className={ styles['chiliz-banner'] }>
      <BannerMain/>
      <BannerVideo/>
    </div>
  )
}
