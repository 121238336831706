import React from 'react'
import styles from './index.module.scss'

export const HomeFooter: React.FC = () => {
  return (
    <div
      className={ styles['home-footer__container'] }
      data-testid="home-footer"
    >
      <img
        className={ styles['home-footer__image-polygon'] }
        src="/by-polygon.png"
        alt="Powered by Polygon"
        lang="en"
      />

      <img
        className={ styles['home-footer__image-thirdweb'] }
        src="/by-thirdweb.png"
        alt="Powered by Thirdweb"
        lang="en"
      />

      <img
        className={ styles['home-footer__image-chiliz'] }
        src="/by-chiliz.png"
        alt="Powered by Chiliz"
        lang="en"
      />
    </div>
  )
}
