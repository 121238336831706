import React from 'react'
import { Organizer } from '~/domain/Organizer'
import styles from './index.module.scss'

interface OrganizerCardProps {
  organizer: Organizer
}

export const OrganizerCard: React.FC<OrganizerCardProps> = ({ organizer }) => {
  return (
    <div className={ styles['organizer-card__card'] }>
      <img
        className={ styles['organizer-card__image'] }
        src={ organizer.cardImageUrl }
        alt={ `Card of ${organizer.acronym}` }
      />

      <p className={ styles['organizer-card__acronym'] }>
        { organizer.acronym }
      </p>
      <p className={ styles['organizer-card__name'] }>
        { organizer.name }
      </p>
    </div>
  )
}
