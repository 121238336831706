import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { LinkButton } from '~/components/LinkButton'
import styles from './index.module.scss'

export const Link: React.FC = () => {
  return (
    <div>
      <LinkButton
        href="/club"
        size="large"
        hierarchy="primary"
        icon={ faArrowRight }
        className={ styles['link__button'] }
      >
        Claim Your NFT
      </LinkButton>
    </div>
  )
}
