import React from 'react'
import styles from './index.module.scss'

export const VaultsDescription: React.FC = () => {
  return (
    <div data-testid="vault-showcase-description">
      <h1 className={ styles['vault-description__title'] }>
        Vaults
      </h1>

      <p className={ styles['vault-description__description'] }>
        Support your favorite athletes. Own a piece of their legacy.
      </p>
    </div>
  )
}
