import React from 'react'
import styles from './index.module.scss'

export const ShowcaseDescription: React.FC = () => {
  return (
    <div data-testid="series-showcase-description">
      <h1 className={ styles['showcase-description__title'] }>
        Series
      </h1>

      <p className={ styles['showcase-description__description'] }>
        Explore our top competitions.
      </p>
    </div>
  )
}
