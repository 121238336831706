import React from 'react'
import { useFeature } from 'flagged'
import { BannerMain } from './BannerMain'
import { BannerVideo } from './BannerVideo'
import styles from './index.module.scss'

export const ClubBanner: React.FC = () => {
  const isLegendClub = useFeature('legendClub')

  if (!isLegendClub) return null

  return (
    <div className={ styles['club-banner'] }>
      <BannerMain/>
      <BannerVideo/>
    </div>
  )
}
