import React from 'react'
import { VaultWithCollectionAndTokenId } from '~/domain/Vault'
import { VaultCard } from '~/components/VaultCard'
import styles from './index.module.scss'

interface Props {
  vaults: VaultWithCollectionAndTokenId[]
}

export const VaultCardsList: React.FC<Props> = ({ vaults }) => {
  const cards = vaults.map((vault) => {
    return (
      <VaultCard
        vault={ vault }
        key={ vault.contract.address }
      />
    )
  })

  return (
    <div
      className={ styles['vault-list__list'] }
      data-testid="vault-showcase-list"
    >
      { cards }
    </div>
  )
}
