import React from 'react'
import { chilizDropName } from '~/constants/site'
import styles from './index.module.scss'

export const Description: React.FC = () => {
  return (
    <div className={ styles['description'] }>
      <h1 className={ styles['description__title'] }>
        { chilizDropName }
      </h1>

      <p className={ styles['description__text'] }>
        NFT collection for the most loyal members of the Legend & Chiliz communities.
      </p>
    </div>
  )
}
