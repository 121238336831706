export const Logo: React.FC = () => {
  return (
    <div>
      <img
        src="/legend-logo.svg"
        alt="Legend Logo"
      />
    </div>
  )
}
