import React from 'react'
import { useFeature } from 'flagged'
import { BannerMain } from './BannerMain'
import { BannerImage } from './BannerImage'
import styles from './index.module.scss'

export const Euro2024Banner: React.FC = () => {
  const isEuro2024 = useFeature('euro2024')

  if (!isEuro2024) return null

  return (
    <div className={ styles['euro2024-banner'] }>
      <BannerMain/>
      <BannerImage/>
    </div>
  )
}
