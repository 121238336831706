import React from 'react'
import { Series } from '~/domain/Series'
import { ShowcaseDescription } from './ShowcaseDescription'
import { SeriesList } from './SeriesList'
import styles from './index.module.scss'

interface SeriesShowcaseProps {
  series: Series[]
}

export const SeriesShowcase: React.FC<SeriesShowcaseProps> = ({ series }) => {
  return (
    <div
      className={ styles['series-showcase__slide'] }
      data-testid="series-showcase"
    >
      <div className={ styles['series-showcase__container'] }>
        <ShowcaseDescription />

        <SeriesList series={ series }/>
      </div>
    </div>
  )
}
