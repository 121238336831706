import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { LinkButton } from '~/components/LinkButton'
import styles from './index.module.scss'

export const BannerMain: React.FC = () => {
  return (
    <div className={ styles['banner-main'] }>
      <div className={ styles['banner-main__title'] }>
        Join the Paris 2024 Basketball Staking Game
      </div>

      <div className={ styles['banner-main__description'] }>
        Stake on Polygon for your favorite teams and win awesome rewards. <br/>
        8,000 MATIC boost distributed across match pools!
      </div>

      <LinkButton
        href="/paris2024"
        size="medium"
        hierarchy="primary"
        icon={ faArrowRight }
        className={ styles['banner-main__button'] }
      >
        Start Now
      </LinkButton>
    </div>
  )
}
