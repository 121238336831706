import React from 'react'
import { useFeature } from 'flagged'
import { BannerMain } from './BannerMain'
import { BannerImage } from './BannerImage'
import styles from './index.module.scss'

export const Paris2024Banner: React.FC = () => {
  const isParis2024 = useFeature('paris2024')

  if (!isParis2024) return null

  return (
    <div className={ styles['paris2024-banner'] }>
      <BannerMain/>
      <BannerImage/>
    </div>
  )
}
