import React from 'react'
import { Logo } from './Logo'
import { Description } from './Description'
import { Link } from './Link'
import styles from './index.module.scss'

export const BannerMain: React.FC = () => {
  return (
    <div className={ styles['banner-main'] }>
      <Logo/>
      <Description/>
      <Link/>
    </div>
  )
}
