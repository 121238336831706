import type { GetServerSideProps } from 'next'
import { HomePage, HomePageProps } from '~/components/HomePage'
import { defaultOgImageUrl } from '~/constants/site'
import { getFeaturedAthletes } from '~/services/athletes'
import { getFeaturedNft } from '~/services/nft'
import { getOrganizers } from '~/services/organizers'
import { getSeries } from '~/services/series'
import { getLatestVaultsWithCollectionAndTokenId } from '~/services/vaults'

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (context) => {
  const featuredVaults = await getLatestVaultsWithCollectionAndTokenId(3)
  const featuredNft = getFeaturedNft()
  const featuredSeries = getSeries()
  const featuredAthletes = getFeaturedAthletes()
  const featuredOrganizers = getOrganizers()
  const url = `https://${context.req?.headers.host}${context.resolvedUrl}`

  if (featuredSeries.length === 0) {
    return { notFound: true }
  }

  return {
    props: {
      featuredNft,
      featuredSeries,
      featuredAthletes,
      featuredOrganizers,
      featuredVaults,
      url,
      ogImageUrl: defaultOgImageUrl,
    },
  }
}

export default HomePage
