import React from 'react'
import { Athlete } from '~/domain/Athlete'
import { AthleteCard } from './AthleteCard'
import styles from './index.module.scss'

interface AthleteShowcaseProps {
  athletes: Athlete[]
}

export const AthleteShowcase: React.FC<AthleteShowcaseProps> = ({ athletes }) => {
  const carousel = athletes.map((athlete) => {
    return (
      <AthleteCard
        key={ athlete.id }
        athlete={ athlete }
      />
    )
  })

  return (
    <div
      data-testid="athlete-showcase"
      className={ styles['athlete-showcase__slide'] }
    >
      <h1 className={ styles['athlete-showcase__title'] }>
        Athletes
      </h1>

      <p className={ styles['athlete-showcase__description'] }>
        Meet our legends.
      </p>

      <div className={ styles['athlete-showcase__carousel'] }>
        { carousel }
        { carousel }
      </div>
    </div>
  )
}
