export const Logo: React.FC = () => {
  return (
    <div>
      <img
        src="/chiliz-legend-logo.png"
        alt="Chiliz x Legend Logo"
      />
    </div>
  )
}
