import React from 'react'
import { useFeature } from 'flagged'
import { MediaRenderer } from '@thirdweb-dev/react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { LinkButton } from '~/components/LinkButton'
import { FeaturedNft } from '~/domain/FeaturedNft'
import styles from './index.module.scss'

interface HomeHeaderProps {
  nft: FeaturedNft
}

export const HomeHeader: React.FC<HomeHeaderProps> = ({ nft }) => {
  const isLive = useFeature('live')

  return (
    <div
      className={ styles['home-header__slide'] }
      data-testid="home-header"
    >
      <div className={ styles['home-header__container'] }>
        <div className={ styles['home-header__card'] }>
          <div className={ styles['home-header__side-texts'] }>
            <span className={ styles['home-header__top-left-text'] }>
              <strong>Sports +</strong><br />Technology
            </span>
            <span className={ styles['home-header__center-left-text'] }>
              <strong>NFT Collectibles</strong><br />Digital Assets
            </span>
            <span className={ styles['home-header__bottom-left-text'] }>
              <strong>LEGEND</strong><br />Official Marketplace
            </span>

            <span className={ styles['home-header__top-right-text'] }>
              <strong>Official Trophies</strong><br />Athletes
            </span>
            <span className={ styles['home-header__center-right-text'] }>
              <strong>NFT Collectibles</strong><br />Digital Assets
            </span>
            <span className={ styles['home-header__bottom-right-text'] }>
              <strong>LEGEND</strong><br />Club
            </span>
          </div>

          <h1
            className={ styles['home-header__title'] }
            data-testid="home-header-title"
          >
            Be a legend.<br/>
            Collect and support.
          </h1>

          <p className={ styles['home-header__subtitle'] }>
            The only marketplace where athletes and supporters gather together to trade Official NFT
            Trophies.
          </p>

          <div className={ styles['home-header__media'] }>
            <MediaRenderer
              src={ nft.animationUrl }
              poster={ nft.imageUrl }
              alt={ nft.name }
              width={ nft.width }
              controls={ false }
              data-testid="home-header-media"
            />
          </div>

          {
            isLive && <LinkButton
              href={ nft.marketplaceUrl }
              hierarchy="primary"
              className={ styles['home-header__button'] }
              icon={ faArrowRight }
            >
              View NFT
            </LinkButton>
          }
        </div>
      </div>
    </div>
  )
}
