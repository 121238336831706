import React from 'react'
import styles from './index.module.scss'

export const Description: React.FC = () => {
  return (
    <div className={ styles['description'] }>
      <h1 className={ styles['description__title'] }>
        Be part of the Legend Club
      </h1>

      <p className={ styles['description__text'] }>
        The Legend Club is an exclusive membership that provides you with unique benefits.
      </p>
    </div>
  )
}
